import { useState, useEffect } from 'react';

import Box from '@mui/material/Box'
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography'

import { Iinfo, Ipolicy } from '../../types/interface'
import { colors } from '../../colors/colors';
import { theme } from '../../App';


interface Iprops {
    info: Iinfo
}
const Policy = (props:Iprops) => {
    const { info } = props

    const [data, setData] = useState<Ipolicy[]>([])

    useEffect(() => {
        fetch(`/info/policy.json`)
        .then(res => res.json())
        .then(res => {            
            setData(res?.Policy ||[])
        })    
      return () => {        
      }
    }, [])

    return (
        <Box component="section" width={"100vw"} sx={{minHeight:"100vh"}} className="flex__center-c">

            <Box maxWidth="xl" width="95%" m="5rem 0">
                <Typography variant='h1' sx={{m:"2rem 0"}}>{info?.Policy?.Title}</Typography>
                <Typography variant='subtitle1' sx={{textAlign:"left",mb:'2rem'}}>{info?.Policy?.Info}</Typography>


                {data?.map((item, i) =>{                    
                    return (
                        <Accordion key={`accordion${item?.title}`} sx={{borderLeft:`2px solid ${theme?.palette?.secondary?.light}`,mb:"0.5rem"}}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}                                        
                            >
                                <Typography variant='h6' sx={{textAlign:"left"}}>{item?.title}</Typography>
                            </AccordionSummary>
                            <AccordionDetails  sx={{whiteSpace:"pre-line"}}>
                                <Typography variant='subtitle1' sx={{textAlign:"left"}} dangerouslySetInnerHTML={ {__html: item?.info} }></Typography>
                            </AccordionDetails>
                        </Accordion>
                    )
                } )}
                

                <Typography variant='subtitle1' sx={{textAlign:"left",mt:"2rem"}}>{info?.Policy?.Address}</Typography>
                <Typography variant='subtitle1' sx={{textAlign:"left",}}>{info?.Policy?.Phone}</Typography>
                <Typography variant='subtitle1' sx={{textAlign:"left",}}>{info?.Policy?.Email}</Typography>


            </Box>
            
        </Box>
    )
}

export default Policy