import { useEffect, useState } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'

import { StaffCard } from '../../components/index'
import { Istaff,Iinfo } from '../../types/interface'

interface Iprops {
    info: Iinfo
}
const Staff = (props:Iprops) => {
    const { info } = props


    const [ data, setData ] = useState<Istaff []>([])

    useEffect(() => {
        fetch(`/info/staff.json`)
        .then(res => res.json())
        .then(res => {            
            setData(res?.Staff)
        })    
      return () => {        
      }
    }, [])


    return (
        <Box width={"100vw"} className="flex__center-c">


            <Box sx={{height:"50vh",position:"relative",width:"100vw",mt:"5rem"}}>
                <img className="header-img-noblur" src={info?.Staff?.Image} alt='' style={{height:"100%"}}/>
            </Box>

            <Box width={"100%"} maxWidth="xl" className="flex__center-c">

                <Typography variant='h1' mt={"10rem"} sx={{fontSize:"3rem !important"}} color="primary" >
                    {info?.Staff?.Title}
                </Typography>

                <Typography variant='subtitle1' m={"2rem 0 5rem 0"} color=""  maxWidth="95%">
                    {info?.Staff?.Info}
                </Typography>


                {/* Office Staff */}
                <Typography variant='h2' >
                    {info?.Staff?.Title_Office}
                </Typography>

                <Grid className="flex__center-c" container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"0.1rem"} sx={{maxWidth:"90%",alignItems:"flex-start"}}>
                {(data || []).map((item,i) => {        
                    if(item.category === "office"){
                        return(                    
                            <StaffCard info={info} staff={item} key={`staffitems${item?.title}${i}`} />                                        
                        )
                    }else{return null}         
                    
                })}
                </Grid>


                {/* Outside Staff */}
                <Typography variant='h2' >
                    {info?.Staff?.Title_Outside}
                </Typography>

                <Grid className="flex__center-c" container rowSpacing={20} spacing={{ xs: 2, md: 3 }} marginTop={"0.1rem"} sx={{maxWidth:"90%"}}>
                {(data || []).map((item,i) => {        
                    if(item.category === "outside"){
                        return(                    
                            <StaffCard info={info} staff={item} key={`staffitems${item?.title}${i}`} />                                        
                        )
                    }else{return null}         
                    
                })}
                </Grid>

            </Box>
        </Box>
        )
}

export default Staff