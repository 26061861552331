import { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
    
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import useMediaQuery from '@mui/material/useMediaQuery'

import { HandleLoadingImages } from '../../Functions/HandleLoadingImages'
import { Iinfo, Iservicecards } from '../../types/interface'

const defaultImg = "/images/defaultprofile.webp"
const imageStyle ={
    text:{color:"#ffffffff",transition:"color 0.5s ease-in-out",maxWidth:"98%"},
    img: { 
        transition:"transform 1.5s ease-in-out",
        objectFit:"cover" as any,
        height:"100%",
        width:"100%",
        position:"absolute" as any,
        zIndex:-1 
    },
    box:{
        position:"relative",
        backgroundColor:"#0000004e",
        transition:"background-color 0.5s ease-in-out",
        overflow:"hidden",        
        //m:"1rem 0",
        cursor:"pointer",
        ":hover":{
            "backgroundColor": "#00000082",            
        },
        ":hover img":{          
            "transform": "scale3d(1.1,1.1,1.1)"
        },
        ":hover h3":{
            "color": "#ffffffff"
        }
    }
}

interface Iprops {
    info: {[key:string]:string}
    items: Iservicecards[]
}
const ServiceMainPage = (props:Iprops) => {
    const { info, items } = props
    
    const image_ref = useRef(0)
    const navigate = useNavigate()
    const mobile = useMediaQuery("(max-width:690px)")

    const [ loading, setLoading ] = useState(true)
    
    

    const [  data, setData ] = useState<Iservicecards []>(items)

    useEffect(() => {
        
        setData(items)
    
        return () => {
      
        }
    }, [info])
    

    return (
        <Box width={"100vw"} className="flex__center-c">


            
            <Box sx={{height:"50vh",position:"relative",width:"100vw",mt:"5rem"}}>
                <img className="header-img-noblur" src={info?.Image} alt='' style={{height:"100%"}}/>
            </Box>

            <Box width={"100%"} maxWidth="xl" className="flex__center-c">

                <Typography variant='h2' mt={"10rem"} color="primary"  maxWidth="95%">
                    {info?.Title}
                </Typography>

                <Typography variant='subtitle1' m={"2rem 0 5rem 0"} color="" maxWidth="95%">
                    {info?.Info}
                </Typography>
            </Box>

            <Box sx={{minHeight:"100vh",pt:"5rem",width:"99vw",mb:"5rem",justifyContent:"flex-start"}} width={"100%"} maxWidth="lg" className="flex__center-c">
            {/* <Box component="section"  className='flex__center-c' > */}
             
                {(data || []).map((item,i) => {
                    const otherData = item
                    

                    if(mobile){
                        return(
                            <Box width={"100%"} height={mobile ? "30vh" : "50vh" } sx={imageStyle?.box} className="flex__center-c"  onClick={()=>navigate(otherData?.link)} key={`serviceitem1mobile${item?.title}${i}`}>
                                <Typography variant='h2'  sx={imageStyle?.text}>
                                    {otherData?.title}
                                </Typography>
                                <img src={otherData?.image} style={imageStyle.img} alt='' onLoad={()=>HandleLoadingImages(data.length, image_ref, setLoading)}/>
                            </Box>
                        )
                    }
                    return(                                            
                        

                            <Card className="flex__center-r flex__r-c" sx={{m:"2rem 0",height:"15rem",width:"100%",justifyContent:"space-between",backgroundColor:"#e0e0e0",boxShadow:"0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)"}} key={`serviceitem1${item?.title}${i}`}>
                                
                                <img 
                                    src={`/${otherData?.image}`}
                                    alt=""  style={{height:"100%",minWidth: mobile ? "100%" : "50%" , objectFit:"cover"}} 
                                    onError={(item)=> item.currentTarget.src = defaultImg }
                                />
            
                                <Box width={"90%"} className="flex__center-c" sx={{justifyContent:"space-around",height:"100%"}}>
                                    <Typography sx={{color:"#606061",textAlign:"center",m:"0.5rem 0"}} variant="h4">{otherData?.title}</Typography>
                                    <Typography color="grey.800" sx={{textAlign:"center",m:"0.5rem 0",width:"98%"}} variant="subtitle2">{otherData?.subTitle}</Typography>
                                    <Button onClick={()=>navigate(`${otherData?.link}`)} variant='outlined'>{info?.Button_MoreInfo}</Button>
                                </Box> 
                                                                
                            </Card>
                        
                    )                                        
                })}
                
            </Box>
        </Box>
    )
}

export default ServiceMainPage