import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'

import EmailIcon from '@mui/icons-material/Email';


import { Iinfo, Istaff } from '../../types/interface'

const defaultImg = "/images/defaultprofile.webp"
interface Iprops {
    info: Iinfo
    staff: Istaff 
}
const StaffCard = (props:Iprops) => {
    const { staff } = props
 
    return (
        <Grid item xs={12} sm={6} md={4} className="flex__center-c" sx={{p:"2rem 0 !important"}}
              
            >
            <Card className="flex__center-c" sx={{height:"40rem",width:"20rem",justifyContent:"space-between"}}>
                
                <img src={`${staff?.image}`} alt=""  style={{height:"50%",width:"100%", objectFit:"cover"}} onError={(item)=> item.currentTarget.src = defaultImg } />

                <Box width={"90%"} className="flex__center-c" sx={{justifyContent:"flex-start"}}>
                    <Typography sx={{textAlign:"center",m:"0.5rem 0"}} variant="h3">{staff?.name}</Typography>
                    <Typography color="grey.500" sx={{textAlign:"center",m:"0.5rem 0"}} variant="h5">{staff?.title}</Typography>
                    <Typography color="grey.500" sx={{textAlign:"center",mb:"2rem", height:"28px",
                        display: "-webkit-box",
                        overflow: "hidden",
                        "WebkitLineClamp": "6",
                        "WebkitBoxOrient": "vertical"
                    }} variant="subtitle1">{staff?.info}</Typography>                    
                </Box> 


                
                <Box>
                {staff?.email ?
                    <Box className="flex__center-r" mb="0.5rem">
                        <EmailIcon color='secondary' />
                        <Typography color="grey.500" sx={{ml:"0.2rem"}} variant="subtitle1">{staff?.email}</Typography>
                    </Box>
                    :
                    <Box className="flex__center-r" mb="0.5rem">
                        <Typography color="#ffffff00" sx={{ml:"0.2rem"}} variant="subtitle1" >_</Typography>
                    </Box>
                }
                </Box>
                


            </Card>
        </Grid>
    )
}

export default StaffCard