import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'







//const serverAddress = process.env.NODE_ENV === "production" ? `https://api.${process.env.REACT_APP_COMPONENT}.se` : "http://localhost:5000"
const serverAddress =  `https://api.${process.env.REACT_APP_COMPONENT}.se` 





const initalStateValue = { status: "inital"}

export const PostContact = createAsyncThunk(
    "post/PostContact ",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.post(`${serverAddress}/postcontact`,info)        
        return res.data
    } catch (error) {
        console.log(error)   
        return {res: {statusCode:400}}
    }
})

const postSlice = createSlice({
    name:"fetchData",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {                  
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
    }
})

const { reducer } = postSlice

export default reducer

 